import classes from './Footer.module.css'

function Footer() {

    return (
        <footer className={classes.backFooter}>
            <div className="container pt-4">
                <div className="flex-row flex-wrap justify-content-evenly">
                    <div className="col-12 col-md-4 justify-content-center">
                        <div className="align-items-center">
                            <h4>Residência Sénior Miguel Torga</h4>
                            <p className="lead fst-italic text-muted">Cuidamos de quem cuidou de si...</p>
                        </div>
                        <div
                            className={`${classes.logosSection} d-flex flex-row align-self-center`}>
                            <div className="col-1 h-100">
                                <a
                                    className="btn btn-link btn-floating btn-lg text-dark m-1 h-100 text-decoration-none"
                                    href="https://www.facebook.com/Resid%C3%AAncia-S%C3%A9nior-Miguel-Torga-106767442133566"
                                    role="button"
                                    data-mdb-ripple-color="dark"
                                ><i className="bi bi-facebook fs-2"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 align-items-center mt-2 mt-md-0">
                        <h5 className="mb-4 align-self-center text-muted">Morada</h5>
                        <div className="col-8">
                            <a className="text-decoration-none flex-row" href="https://goo.gl/maps/nDgpzERQosw1VKe27">
                                <div className="flex-row">
                                    <i className="bi bi-geo-alt-fill text-dark pe-2"></i>
                                    <div className="m-0 p-0 small">
                                        <p>Rua Fernando Pessoa nº81</p>
                                        <p>4560-893 Penafiel</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 align-items-center my-2 my-md-0">
                        <h5 className="mb-4 align-self-center text-muted">Contactos</h5>
                        <div className="col-8">
                            <div className="flex-row small">
                                <i className="bi bi-envelope-paper-fill text-dark pe-2"></i>
                                <p>secretaria@residenciaseniormt.pt</p>
                            </div>
                            <div className="flex-row small">
                                <i className="bi bi-phone-vibrate-fill text-dark pe-2"></i>
                                <p>914168543</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.rights} mt-3 py-3 text-white-50`}>
                <p className="me-3 mb-0">© 2022 Residência Sénior Miguel Torga - Penafiel</p>
            </div>
        </footer>
    )
}

export default Footer;