import {Fragment} from "react";
import classes from './Layout.module.css';
import MainNavigation from "./MainNavigation";
import Footer from "./Footer";
import App from "../../App";
import {Helmet} from "react-helmet";

function Layout() {
    return (
        <Fragment>
            <Helmet>
                <title>Residência Sénior Miguel Torga - Lar de Idosos Penafiel</title>
                <meta name="description"
                      content="O seu Lar de Idosos em Penafiel. Residência Sénior Miguel Torga. Em Penafiel encontra a Residência sénior com as mais melhores instalações e os serviços mais adequados aos nossos idosos."/>
                <meta name="keywords"
                      content="Residência sénior, Lar Idosos em Penafiel, Lar Miguel Torga Penafiel, Lar de Idosos em Penafiel, Residência Sénior Miguel Torga Penafiel"/>
            </Helmet>
            <div className={classes.body}>
                <MainNavigation/>
                <main className={classes.main}><App/></main>
                <Footer/>
            </div>
        </Fragment>
    )
}

export default Layout;