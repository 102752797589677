import {NavLink} from "react-router-dom";
import classes from './MainNavigation.module.css';
import {useEffect, useState} from "react";
import {Link} from "react-scroll";

function MainNavigation(props) {
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const stickyheader = document.getElementById("myNav");
        setHeaderTop(stickyheader.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <nav id="myNav"
             className={`${classes.navBarStyle} navbar fixed-top navbar-light navbar-expand-lg fixed-top ${scroll > headerTop ? classes.affix : ""}`}>
            <div className={`${classes.limitNavWidth} container-fluid flex-row`}>
                <a className="navbar-brand" href="/">
                    <div className={classes.logoImg}>
                            <span className={classes.logoSize}>
                                <img style={{width: "100%", height: "100%"}} src={"/logo_with_transp.png"}
                                     alt="Lar de Idosos Penafiel"/>
                            </span>
                    </div>
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div id="navbarSupportedContent" className="collapse navbar-collapse align-items-start">
                    <ul className="nav navbar-nav ms-auto align-items-end">
                        <li className="nav-item mx-2">
                            <Link to="inscricao">Inscrições</Link>
                        </li>
                    </ul>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className={`align-items-center navbar-nav ms-auto mb-2 mb-lg-0`}>
                        <li className="nav-item mx-3">
                            <div className={classes.homeImg} data-bs-toggle="tooltip" data-bs-placement="bottom"
                                 title="Página Inicial">
                                <NavLink to={"/"}>
                                    <img src="/claudita-home-icon.svg" alt="home button"/>
                                </NavLink>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default MainNavigation;