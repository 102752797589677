import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Layout from "./components/layout/Layout";
import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Helmet>
                <title>Residência Sénior Miguel Torga - Lar de Idosos Penafiel</title>
                <meta name="description"
                      content="O seu Lar de Idosos em Penafiel. Residência Sénior Miguel Torga. Em Penafiel encontra a Residência sénior com as mais melhores instalações e os serviços mais adequados aos nossos idosos."/>
                <meta name="keywords"
                      content="Residência sénior, Lar Idosos em Penafiel, Lar Miguel Torga Penafiel, Lar de Idosos em Penafiel, Residência Sénior Miguel Torga Penafiel"/>
            </Helmet>
            <Layout/>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
