import classes from "./Topics.module.css";

function SignUp() {
    return (
        <section className={`${classes.instalacoes} pt-5`}>
            <div className="flex-row justify-content-center">
                <div className="col-10 col-lg-7 col-md-8 text-wrap">
                    <div className="section-heading text-center mb-2">
                        <h2>Inscrever</h2>
                        <p className="lead text-muted fst-italic">Envie-nos o seu <span
                            className="text-dark">nome</span>, <span className="text-dark">morada</span> e <span
                            className="text-dark">contacto</span> para o email, ou ligue para o número abaixo</p>
                    </div>
                </div>
            </div>
            <div className="mb-1 mt-1 col-10 offset-1 col-md-4 offset-md-4">
                <div className="flex-row mb-2 align-items-center">
                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                        <i className="bi bi-envelope-paper-fill"></i>
                    </div>
                    <p className="mb-0">secretaria@residenciaseniormt.pt</p>
                </div>
                <div className="flex-row mb-2 align-items-center">
                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                        <i className="bi bi-phone-vibrate-fill"></i>
                    </div>
                    <p className={`${classes.letterSpacingBig} mb-0 fw-bolder`}>914168543</p>
                </div>
            </div>
        </section>
    )
}

export default SignUp;