import {Fragment} from "react";
import {Helmet} from "react-helmet";
import classes from './WelcomePage.module.css'
import {Link} from "react-scroll";
import Topics from "./Topics";

function WelcomePage() {
    return (
        <Fragment>
            <Helmet>
                <title>Residência Sénior Miguel Torga - Lar de Idosos Penafiel</title>
                <meta name="description"
                      content="O seu Lar de Idosos em Penafiel. Residência Sénior Miguel Torga. Em Penafiel encontra a Residência sénior com as mais melhores instalações e os serviços mais adequados aos nossos idosos."/>
                <meta name="keywords"
                      content="Residência sénior, Lar Idosos em Penafiel, Lar Miguel Torga Penafiel, Lar de Idosos em Penafiel, Residência Sénior Miguel Torga Penafiel"/>
            </Helmet>
            <section>
                <div className={`${classes.section} ${classes.backImg}`}>
                    <div className={classes.imageAbsolut}>
                        <img className={classes.imageFluid} src="/back_curved.png" alt="background"/>
                    </div>
                    <div className={`${classes.myContainer} col-10 offset-1 col-md-6 offset-md-0`}>
                        <article className={classes.articlePresent}>
                            <h1 className="dark-blue-text mb-3">Residência Sénior</h1>
                            <h2 className="text-uppercase dark-blue-text">Miguel Torga</h2>
                        </article>
                        <div className={`${classes.location} w-75 dark-yellow-text my-1`}>
                            <h2>Penafiel</h2>
                        </div>
                        <Link to="inscricao" className={classes.linkToInscricao}>
                            <div className={classes.sketchy}>
                                Inscrições abertas
                                <i className="bi bi-hand-index ps-3"></i>
                            </div>
                        </Link>
                        <div className={classes.mensagem}>
                            <h1 className={`${classes.hideOnMobile} text-white`}>Lar de Idosos Penafiel</h1>
                            <h3>Cuidamos de quem cuidou de si!</h3>
                        </div>
                    </div>
                </div>

            </section>
            <Topics/>
        </Fragment>
    )
}

export default WelcomePage;