import classes from './Topics.module.css'
import SignUp from "./SignUp";

function Topics() {
    return (
        <div>
            <section className={`${classes.servicos}`}>
                <div className="flex-row justify-content-center">
                    <div className="col-10 col-lg-7 col-md-8 text-wrap">
                        <div className="section-heading text-center mb-2">
                            <h2>Serviços</h2>
                            <p className={`${classes.subTitles} lead text-muted fst-italic`}>Com instalações de elevada
                                qualidade e uma equipa
                                dedicada e competente, a Residência sénior Miguel Torga cuida de si e dos seus.</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.imageBackgroundOne} flex-row justify-content-center col-10 offset-1`}>
                    <div className="col-12 col-md-7">
                        <div className="section-heading pe-1">
                            <div className="mb-1 mt-1">
                                <div className="flex-row mb-2 align-items-center">
                                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                                        <i className="bi bi-clock"></i>
                                    </div>
                                    <p className="mb-0">Serviço permanente 24h</p>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div className="flex-row mb-2 align-items-center">
                                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                                        <i className="bi bi-heart-pulse"></i>
                                    </div>
                                    <p className="mb-0">Assistência médica e de enfermagem</p>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div className="flex-row mb-2 align-items-center">
                                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                                        <i className="bi bi-calendar2-heart"></i>
                                    </div>
                                    <p className="mb-0">Fisioterapia</p>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div className="flex-row mb-2 align-items-center">
                                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                                        <i className="bi bi-palette"></i>
                                    </div>
                                    <p className="mb-0">Atividades ocupacionais e de estimulação cognitiva</p>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div className="flex-row mb-2 align-items-center">
                                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                                        <i className="bi bi-emoji-smile"></i>
                                    </div>
                                    <p className="mb-0">Programa de atividade física sénior</p>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div className="flex-row mb-2 align-items-center">
                                    <div className={`${classes.item} me-3 icon-box fs-4`}>
                                        <i className="bi bi-smartwatch"></i>
                                    </div>
                                    <p className="mb-0">Alojamento temporário de pessoas dependentes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 justify-content-center">
                        <div className={`${classes.topicsImage}`}>
                            <img src="/servicos_image.jpg" alt="servicos"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${classes.instalacoes}`}>
                <div className="flex-row justify-content-center">
                    <div className="col-10 col-lg-7 col-md-8 text-wrap">
                        <div className="section-heading text-center mb-2">
                            <h2>Instalações</h2>
                            <p className={`${classes.subTitles} lead text-muted fst-italic`}>O espaço que você ou os
                                seus mais
                                precisam.</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.imageBackgroundTwo} flex-row justify-content-center col-10 offset-1 mt-2`}>
                    <div className={`${classes.topicsImage} col-md-5 align-items-center`}>
                        <img src="/instalacoes_image.jpg" alt="instalacoes"/>
                    </div>
                    <div className="col-11 col-md-7 align-items-end">
                        <div className="mb-1 mt-1">
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">No centro de Penafiel</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-pin-map"></i>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">Quartos individuais e duplos</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-lamp-fill"></i>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">Espaço verde exterior</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-tree"></i>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">Sala de atividades</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-file-music"></i>
                                </div>
                            </div>
                        </div>
                        <div id="inscricao" className="mb-1">
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">Serviços de apoio (religiosos, saúde e lazer)</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-shop"></i>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">Proximidade do Hospital</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-hospital-fill"></i>
                                </div>
                            </div>
                        </div>
                        <div className={`mb-1`}>
                            <div className="flex-row mb-2 align-items-center">
                                <p className="mb-0">Fácil acesso à Auto-estrada A4</p>
                                <div className={`${classes.item} ms-3 icon-box fs-4`}>
                                    <i className="bi bi-signpost-split"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SignUp/>
        </div>
    )
}

export default Topics;